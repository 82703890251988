<template>
  <div class="estate f-container">
    <div class="head x3">
      <div>
        <el-input
          class="w320 mr10 f-search-bar"
          placeholder="楼盘名称"
          @keyup.enter.native="search"
          v-model="queryData.keyword"
        >
          <el-button slot="append" @click="search">搜索</el-button>
        </el-input>
        <area-cascader
          @setQueryData="setQueryData"
          class="mr10"
        ></area-cascader>
        <el-select
          class="w160"
          @change="search"
          placeholder="物业类型"
          clearable
          v-model="queryData.propertyType"
        >
          <el-option
            v-for="(value, key) in propertyTypesList"
            :label="value"
            :value="Number(key)"
            :key="key"
          ></el-option>
        </el-select>
      </div>
      <el-button type="primary" icon="el-icon-plus" @click="showAddDialog"
        >创建楼盘</el-button
      >
    </div>
    <el-table
      border
      class="mt20"
      :data="list"
      :row-class-name="tableRowClassName"
    >
      <el-table-column label="楼盘信息" width="300">
        <template v-slot="{ row }">
          <div class="base-info">
            <el-image class="cover" :src="row.imageUrl"></el-image>
            <div class="name-wrap">
              <div class="title">
                {{ row.name }}
                <span class="shelvesStatus" v-if="row.shelvesStatus == 2"
                  >已下架</span
                >
              </div>
              <p>{{ row.inUser }}，{{ row.inDate }}</p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="类型/区域" width="160">
        <template v-slot="{ row }">
          <div class="title">
            {{
              row.propertyTypeStrList ? row.propertyTypeStrList.join("、") : ""
            }}
          </div>
          <p>{{ row.regionName }}</p>
        </template>
      </el-table-column>
      <el-table-column label="佣金">
        <template v-slot="{ row }">
          <div class="title" :class="{ red: row.commissionList.length < 1 }">
            {{ commissionStr(row.commissionList) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="营销团队" width="100">
        <template v-slot="{ row }">
          <el-popover
            :ref="`popover${row.id}`"
            placement="bottom-end"
            width="240"
            @show="authCustomerList(row.estateId)"
          >
            <div class="info-list">
              <dl v-for="(item, index) in authList" :key="index">
                <dt>{{ item.label }}</dt>
                <dd>
                  <ul v-if="item.list.length">
                    <li v-for="(d, _idx) in item.list" :key="_idx">
                      <el-image :src="d.authAvatarPath">
                        <img src="@/assets/icon_touxiang.png" slot="error" />
                      </el-image>
                      <span class="name">{{ d.authName }}</span>
                      <span>{{ d.cellphone | formatPhone }}</span>
                    </li>
                  </ul>
                  <ul v-else>
                    <li>
                      <el-image>
                        <img src="@/assets/icon_touxiang.png" slot="error" />
                      </el-image>
                      <span>待添加</span>
                    </li>
                  </ul>
                </dd>
              </dl>
              <div class="tip">
                注：在“用户认证”模块，可以对项目营销号进行添加和维护；
              </div>
            </div>
          </el-popover>
          <el-button
            type="text"
            v-popover:[`popover${row.id}`]
            v-show="row.customerAuthCount"
            >查看({{ row.customerAuthCount }})</el-button
          >
          <div class="title" v-if="!row.customerAuthCount">未设置</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="140">
        <template v-slot="{ row }">
          <el-button type="text" @click="toEstateWorkbench(row)">
            打开管理后台 <i class="el-icon-copy-document"></i>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="排序" width="120">
        <template v-slot="scope">
          <svg-icon
            class="icon icon-order"
            icon-class="xiayi"
            @click="estateSort(scope.row.id, 0)"
            v-if="!(scope.$index === list.length - 1 && isLastPage)"
          ></svg-icon>
          <svg-icon
            class="icon icon-order"
            icon-class="shangyi"
            @click="estateSort(scope.row.id, 1)"
            v-if="!(scope.$index === 0 && isFirstPage)"
          ></svg-icon>
          <svg-icon
            class="icon icon-order"
            icon-class="top"
            @click="estateSort(scope.row.id, -1)"
            v-if="!(scope.$index === 0 && isFirstPage)"
          ></svg-icon>
        </template>
      </el-table-column>
    </el-table>
    <f-pagination
      :page-num.sync="queryData.pageNum"
      :page-size.sync="queryData.pageSize"
      :total="total"
      @change="getList"
    ></f-pagination>
    <edit-estate
      :visible.sync="toggleFormDialog"
      @refresh="search"
    ></edit-estate>
  </div>
</template>

<script>
import { pagedLinkedHouse, platformEstateMapSort } from "@/api/estateManage";
import { customerAuthEstateList } from "@/api/visitorManage";
import { enumeration } from "@/config";
import editEstate from "@/views/estate/_components/edit";
import areaCascader from "@/views/estate/_components/areaCascader";
import { encrypt } from "@/utils/crypto";
let id = 0;
export default {
  components: {
    editEstate,
    areaCascader
  },
  data() {
    return {
      list: [], //table数据
      toggleFormDialog: false,
      toggleStatusDialog: false,
      total: 0, //楼盘总数
      queryData: {
        //查询字段
        // enterpriseId: 0, //企业id
        keyword: "",
        companyCode: "", // 城市
        regionId: "", // 区域
        areaId: "", // 商圈
        propertyType: "",
        propertyTypesList: [],
        pageNum: 1,
        pageSize: 10
      },
      propertyTypesList: enumeration.propertyTypesList, //物业类型
      cityList: [], //城市站点
      areaList: [], //商圈区域
      queryAreaList: [],
      areaCascaderKey: 0, //防止cascader组件切换数据报错
      estateSortFlag: false,
      authList: [], // 认证用户列表
      cascaderTimer: null
    };
  },
  computed: {
    isFirstPage() {
      if (this.queryData.pageNum === 1) {
        return true;
      } else {
        return false;
      }
    },
    isLastPage() {
      const lastPage = Math.ceil(this.total / this.queryData.pageSize);
      if (this.queryData.pageNum === lastPage) {
        return true;
      } else {
        return false;
      }
    }
  },
  created() {
    this.getList();
  },
  methods: {
    search() {
      this.queryData.pageNum = 1;
      this.getList();
    },
    setQueryData(val) {
      // 城市-区域-商圈选择
      this.queryData = { ...this.queryData, ...val };
      this.search();
    },
    async authCustomerList(estateId) {
      // 认证用户
      const res = await customerAuthEstateList(estateId);
      if (res) {
        // authSlaveType: 认证类型：1-地产达人,2-置业顾问, 3-PCN经纪 5 新房助理 6驻场客服 7项目经理
        this.authList = [
          {
            id: 6,
            label: "驻场客服",
            list: res.filter(item => item.authSlaveType == 6)
          },
          {
            id: 7,
            label: "项目经理",
            list: res.filter(item => item.authSlaveType == 7)
          },
          {
            id: 5,
            label: "新房助理",
            list: res.filter(item => item.authSlaveType == 5)
          }
        ];
      }
    },
    // 佣金方案
    commissionStr(arr) {
      if (arr.length) {
        return arr.map(item => item.commissionDesc).join("、");
      } else {
        return "未设置佣金";
      }
    },
    // 给行添加className
    tableRowClassName({ row }) {
      if (row.shelvesStatus == 2) {
        return "disabled";
      }
    },
    async estateSort(id, type) {
      //楼盘排序
      if (this.estateSortFlag) return;
      this.estateSortFlag = true;
      const res = await platformEstateMapSort(id, { type });
      if (res) {
        await this.getList();
        this.estateSortFlag = false;
      }
    },
    async getList() {
      this.queryData.propertyTypesList = this.queryData.propertyType
        ? [this.queryData.propertyType]
        : [];
      const res = await pagedLinkedHouse(this.queryData);
      const { total, list } = res;
      this.total = total;
      this.list = list || [];
    },
    showAddDialog() {
      //点击创建按钮
      this.toggleFormDialog = true;
    },
    toEstateWorkbench(row) {
      this.$router.push({
        path: `/estate/dictionary/baseInfo`,
        query: {
          platformId: encrypt(this.$platformInfo.id),
          estateId: encrypt(row.estateId) // 参数加密
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-table {
  table {
    width: 100% !important;
  }
}
.mt40 {
  margin-top: 40px;
}
.estate {
  .base-info {
    display: flex;
    align-items: center;
    .cover {
      width: 52px;
      height: 52px;
      border-radius: 5px;
      overflow: hidden;
    }
    .name-wrap {
      padding-left: 20px;
      .title {
        font-size: 16px;
        .shelvesStatus {
          font-size: 14px;
          color: #aaa;
          margin-left: 10px;
        }
      }
    }
  }
  .title {
    color: #333;
    line-height: 120%;
    &.red {
      color: #f00;
    }
  }
  p {
    color: #aaa;
    line-height: 100%;
    margin-top: 12px;
  }

  .disabled {
    .el-image {
      opacity: 0.5;
    }
    .title,
    p {
      color: #aaa;
    }
  }
  .body {
    .status {
      float: left;
      height: 24px;
      padding: 0 10px;
      border-radius: 12px;
      cursor: pointer;
      span {
        padding-left: 5px;
      }
      &.status0 {
        color: $red;
        background: $redBg;
      }
      &.status1 {
        background: #eee;
      }
      &.status2 {
        color: $purple;
        background: $purpleBg;
      }
      &.status3 {
        color: $blue;
        background: $blueBg;
      }
    }
  }
}
.icon-order {
  cursor: pointer;
  width: 20px;
  height: 20px;
  & + & {
    margin-left: 15px;
  }
  &:hover {
    color: $primary;
  }
}
.info-list {
  padding: 10px 5px;
  dl {
    margin-bottom: 20px;
  }
  dt {
    color: #aaa;
  }
  .el-image {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  li {
    display: flex;
    align-items: center;
    color: #aaa;
    margin-top: 12px;
    .name {
      color: #333;
    }
    span {
      margin-left: 8px;
    }
  }
  .tip {
    font-size: 13px;
    color: #aaa;
    line-height: 150%;
    border-top: 1px solid #eee;
    padding-top: 15px;
  }
}
</style>
